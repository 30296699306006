import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link, graphql, navigate } from "gatsby"
import React, { useEffect, useState } from "react"

import EventCard from "../components/event-card"
import Layout from "../components/layout/layout"
import Pagination from "../components/pagination"
import Seo from "../components/seo"

const Header = ({ title }) => (
	<div className="px-4 pt-3 sm:flex sm:items-center sm:justify-between">
		<h3 className="text-2xl md:text-4xl font-medium text-gray-900 text-center md:text-left md:mx-14 md:mt-4">
			{title}
		</h3>
		<div className="flex flex-col items-center md:items-end mt-3 sm:mt-0 sm:ml-4 "></div>
	</div>
)

const DateInformation = ({ isOnDemand, from, to }) => {
	if (isOnDemand) {
		return `On Demand`
	} else {
		const commencingFrom = to
			? new Intl.DateTimeFormat("en-AU", {
					timeZone: "Australia/NSW",
					month: "short",
					day: "2-digit",
			  }).format(new Date(from))
			: new Intl.DateTimeFormat("en-AU", {
					timeZone: "Australia/NSW",
					year: "numeric",
					month: "short",
					day: "2-digit",
			  }).format(new Date(from))

		const commencingTo = to
			? new Intl.DateTimeFormat("en-AU", {
					timeZone: "Australia/NSW",
					year: "numeric",
					month: "short",
					day: "2-digit",
			  }).format(new Date(to))
			: ""

		return to ? (
			<strong className="text-white">
				{commencingFrom} - {commencingTo}
			</strong>
		) : (
			<strong className="text-white">{commencingFrom}</strong>
		)
	}
}

const EventList = ({
	pageContext: { currentPage, limit, numPages, route, skip },
	data,
}) => {
	const pageChanged = pageNumber => {
		navigate(`/events/${pageNumber === 1 ? `` : pageNumber}`)
	}
	const filterMonths = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
		"All",
	]

	const filterRegions = [
		{
			abbreviation: "All",
		},
		{
			abbreviation: "Central West",
		},
		{
			abbreviation: "Hunter",
		},
		{
			abbreviation: "Illawarra",
		},
		{
			abbreviation: "Metro North",
		},
		{
			abbreviation: "Metro South",
		},
		{
			abbreviation: "Metro West",
		},
		{
			abbreviation: "Mid North Coast",
		},
		{
			abbreviation: "Northern Inland",
		},
		{
			abbreviation: "North Coast",
		},
		{
			abbreviation: "Orana",
		},
		{
			abbreviation: "South East",
		},
		{
			abbreviation: "South West",
		},
		{
			name: "australian capital territory",
			abbreviation: "ACT",
			capital: "canberra",
			type: "territory",
		},
		{
			name: "new south wales",
			abbreviation: "NSW",
			capital: "sydney",
			type: "state",
		},
	]

	const [selectedFilters, setSelectedFilters] = useState(filterMonths[12])
	const [selectedRegion, setSelectedRegion] = useState(
		filterRegions[0].abbreviation
	)
	const [filteredItems, setFilteredItems] = useState([])
	const [onDemand, setOnDemand] = useState([])

	const [items, setItems] = useState(data.allEventItem.nodes)
	const [loaded, setLoaded] = useState(false)

	//Filter -> Online Events + PD Events = Training
	//Filter -> Everything else Events

	useEffect(() => {
		setFilteredItems([])
	}, [])

	useEffect(async () => {
		if (!loaded) {
			setItems(await sortItems(items))
			await setOnDemandOnly()
			setLoaded(true)
		}
		await handleUpdate()
	}, [selectedFilters, selectedRegion])

	//Show Demand items first
	const sortItems = () => {
		return items.sort((f, s) => {
			return f.isOnDemand === s.isOnDemand ? 0 : f ? -1 : 1
		})
	}

	const setOnDemandOnly = async () => {
		await items.forEach(item => {
			if (item.isOnDemand) setOnDemand(prev => [...prev, item])
		})
	}

	const handleChange = async e => {
		setFilteredItems([])
		switch (e.target.id) {
			case "region":
				setSelectedRegion(e.target.value)
				break
			case "days":
				setSelectedFilters(e.target.value)
				break
		}
	}

	const handleUpdate = async () => {
		const filterIndex = filterMonths.findIndex(
			month => month === selectedFilters
		)

		//loop over all items
		await items.forEach((item, idx) => {
			// if (item.isOnDemand) return

			let createDate = new Date(item.from)
			let date = createDate.getMonth()

			//Check if the item is on the selected date
			if (date !== filterIndex && selectedFilters !== filterMonths[12]) return

			//Check if the item is in the selected region
			if (
				selectedRegion !== item.eventRegion &&
				selectedRegion !== filterRegions[0].abbreviation
			)
				return

			if (
				item.eventType === "PD Workshops" ||
				item.eventType === "Online Learning"
			)
				return

			setFilteredItems(prev => [...prev, item])
		})
	}

	return (
		<Layout>
			<Seo title={route[0] === "events" ? "Events list" : "Training list"} />
			<Header
				title={route[0] === "events" ? "Upcoming Events" : "Upcoming Training"}
			/>
			<div className="flex flex-row pt-5 justify-center md:justify-end mr-2 md:mr-32">
				<div className="pr-2 pb-4">
					<p className="text-gray-500 text-sm">Month</p>
					<select
						className="rounded"
						name="days"
						id="days"
						onChange={handleChange}
						value={selectedFilters}
					>
						{filterMonths.map((day, idx) => (
							<option
								className="hover:bg-primary focus:bg-primary"
								key={idx}
								value={day}
							>
								{day}
							</option>
						))}
					</select>
				</div>
				<div>
					<p className="text-gray-500 text-sm">Region</p>
					<select
						className="rounded focus:outline-none focus:ring-2 focus:primary"
						name="region"
						id="region"
						onChange={handleChange}
						value={selectedRegion}
					>
						{filterRegions.map((state, idx) => (
							<option
								className="hover:bg-primary"
								key={idx}
								value={state.abbreviation}
							>
								{state.abbreviation}
							</option>
						))}
					</select>
				</div>
			</div>

			<div className="min-h-screen bg-gray-200 py-8 md:py-16 px-1/12">
				<ul
					role="list"
					className="divide-y divide-gray-200 px-2 md:px-16 lg:px-28"
				>
					<div className="mx-2 grid gap-8 md:grid-cols-2 lg:grid-cols-3 md:gap-8 lg:gap-6 xl:gap-10 md:mx-auto px-1 text-md text-white pt-2 pb-8 md:pb-24 md:px-auto">
						{filteredItems.map((item, idx) => (
							<EventCard item={item} key={idx} />
						))}
					</div>
				</ul>
			</div>
			<Pagination
				onPageChange={pageChanged}
				pageCount={numPages}
				currentPage={currentPage}
				pageSize={limit}
			/>
		</Layout>
	)
}
export default EventList

export const pageQuery = graphql`
	query EventListQuery($skip: Int!, $limit: Int!) {
		allEventItem(
			sort: { fields: from, order: ASC }
			limit: $limit
			skip: $skip
			filter: {
				eventType: { nin: ["PD Workshops", "Online Learning"] }
				viewInList: { ne: false }
			}
		) {
			nodes {
				eventId
				permalink
				eventType
				eventRegion
				name
				location
				abstract
				isOnDemand
				from
				to
				addressCity
				addressState
				thumbnailImages {
					childImageSharp {
						gatsbyImageData
					}
				}
			}
		}
	}
`
