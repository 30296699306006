import React, { useMemo } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid"

const ResponsivePagination = ({
	canClickPrev,
	canClickNext,
	prevClicked,
	nextClicked,
}) => (
	<div className="flex-1 flex justify-between sm:hidden">
		<button
			onClick={prevClicked}
			disabled={!canClickPrev}
			className="relative btn btn-secondary"
		>
			Previous
		</button>
		<button
			onClick={nextClicked}
			disabled={!canClickNext}
			className="relative btn btn-secondary"
		>
			Next
		</button>
	</div>
)

const PaginationButton = ({
	children,
	disabled,
	isFirst,
	isLast,
	isSelected,
	onClick,
}) => {
	return (
		<button
			className={classNames(
				"relative inline-flex items-center border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50",
				{
					"px-2 py-2 rounded-l-md": isFirst,
					"px-2 py-2 rounded-r-md": isLast,
					"px-4 py-2": !(isFirst || isLast),
					"z-10 bg-indigo-50 border-indigo-500 text-indigo-600": isSelected,
				}
			)}
			disabled={disabled}
			onClick={onClick}
		>
			{children}
		</button>
	)
}

const DesktopPagination = ({
	canClickPrev,
	canClickNext,
	currentPage,
	paginationRange,
	onPageChange,
}) => (
	<div className="flex">
		<nav
			className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
			aria-label="Pagination"
		>
			<PaginationButton
				isFirst={true}
				disabled={!canClickPrev}
				onClick={() => onPageChange(currentPage - 1)}
			>
				<span className="sr-only">Previous</span>
				<ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
			</PaginationButton>
			{/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
			{paginationRange.map(v => {
				return v === DOTS ? (
					<span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
						...
					</span>
				) : (
					<PaginationButton
						key={v}
						isSelected={v === currentPage}
						disabled={v === currentPage}
						onClick={() => onPageChange(v)}
					>
						{v}
					</PaginationButton>
				)
			})}
			<PaginationButton
				isLast={true}
				disabled={!canClickNext}
				onClick={() => onPageChange(currentPage + 1)}
			>
				<span className="sr-only">Next</span>
				<ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
			</PaginationButton>
		</nav>
	</div>
)

const DOTS = "..."
const range = (start, end) => {
	let length = end - start + 1
	/*
		Create an array of certain length and set the elements within it from
		start value to end value.
	*/
	return Array.from({ length }, (_, idx) => idx + start)
}

export const usePagination = ({
	currentPage,
	pageCount,
	pageSize,
	siblingCount = 1,
}) => {
	const paginationRange = useMemo(() => {
		// Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
		const totalPageNumbers = siblingCount + 5

		/*
			Case 1:
			If the number of pages is less than the page numbers we want to show in our
			paginationComponent, we return the range [1..pageCount]
		*/
		if (totalPageNumbers >= pageCount) {
			return range(1, pageCount)
		}

		/*
			Calculate left and right sibling index and make sure they are within range 1 and pageCount
		*/
		const leftSiblingIndex = Math.max(currentPage - siblingCount, 1)
		const rightSiblingIndex = Math.min(currentPage + siblingCount, pageCount)

		/*
			We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and pageCount. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < pageCount - 2
		*/
		const shouldShowLeftDots = leftSiblingIndex > 2
		const shouldShowRightDots = rightSiblingIndex < pageCount - 2

		const firstPageIndex = 1
		const lastPageIndex = pageCount

		/*
			Case 2: No left dots to show, but rights dots to be shown
		*/
		if (!shouldShowLeftDots && shouldShowRightDots) {
			let leftItemCount = 3 + 2 * siblingCount
			let leftRange = range(1, leftItemCount)

			return [...leftRange, DOTS, pageCount]
		}

		/*
			Case 3: No right dots to show, but left dots to be shown
		*/
		if (shouldShowLeftDots && !shouldShowRightDots) {
			let rightItemCount = 3 + 2 * siblingCount
			let rightRange = range(pageCount - rightItemCount + 1, pageCount)
			return [firstPageIndex, DOTS, ...rightRange]
		}

		/*
			Case 4: Both left and right dots to be shown
		*/
		if (shouldShowLeftDots && shouldShowRightDots) {
			let middleRange = range(leftSiblingIndex, rightSiblingIndex)
			return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex]
		}
	}, [pageCount, pageSize, siblingCount, currentPage])

	return paginationRange
}

function Pagination({
	currentPage,
	onPageChange,
	siblingCount = 1,
	pageSize,
	pageCount,
}) {
	const paginationRange = usePagination({
		currentPage,
		pageCount,
		pageSize,
		siblingCount,
	})

	// If there are less than 2 times in pagination range we shall not render the component
	if (currentPage === 0 || paginationRange.length < 2) {
		return null
	}

	const lastPage = paginationRange[paginationRange.length - 1]
	const canClickPrev = currentPage !== 1
	const canClickNext = currentPage !== lastPage

	return (
		<div className="bg-white py-3 flex justify-center border-t border-gray-200">
			<ResponsivePagination
				canClickPrev={canClickPrev}
				canClickNext={canClickNext}
				prevClicked={() => onPageChange(currentPage - 1)}
				nextClicked={() => onPageChange(currentPage + 1)}
			/>
			<DesktopPagination
				canClickPrev={canClickPrev}
				canClickNext={canClickNext}
				currentPage={currentPage}
				paginationRange={paginationRange}
				onPageChange={onPageChange}
			/>
		</div>
	)
}

Pagination.propTypes = {
	onPageChange: PropTypes.func.isRequired,
	pageCount: PropTypes.number.isRequired,
	currentPage: PropTypes.number.isRequired,
	pageSize: PropTypes.number.isRequired,
	siblingCount: PropTypes.number,
}

export default Pagination
